import { CognitoUser, AuthenticationDetails, CognitoUserAttribute } from 'amazon-cognito-identity-js';
import {cognito} from './cognito';


function signin(email, password, onSuccess, onFailure) {
    var authenticationDetails = new AuthenticationDetails({
        Username: toUsername(email),
        Password: password
    });    

    var cognitoUser = createCognitoUser(email);
    cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: onSuccess,
        onFailure: onFailure
    });
}

function createCognitoUser(email) {
    return new CognitoUser({
        Username: toUsername(email),
        Pool: cognito.getUserPool()
    });
}

function toUsername(email) {
    return email.replace('@', '-at-');
}

function register(firstName, lastName, email, password, onSuccess, onFailure) {
    var dataEmail = {
        Name: 'email',
        Value: email
    };
    var attributeEmail = new CognitoUserAttribute(dataEmail);

    var dataFirstName = {
        Name: 'given_name',
        Value: firstName
    };
    var attributeFirstName = new CognitoUserAttribute(dataFirstName);

    var dataLastName = {
        Name: 'family_name',
        Value: lastName
    };
    var attributeLastName = new CognitoUserAttribute(dataLastName);

    cognito.getUserPool().signUp(toUsername(email), password, [attributeEmail, attributeFirstName, attributeLastName], null,
        function signUpCallback(err, result) {
            if (!err) {
                onSuccess(result);
            } else {
                onFailure(err);
            }
        }
    );
}

function forgotPassword(email, onSuccess, onFailure) {
    
    var cognitoUser = createCognitoUser(email);

    cognitoUser.forgotPassword({
        onSuccess: onSuccess,
        onFailure: onFailure
    });
}

function confirmPassword(email, verificationCode, newPassword, onSuccess, onFailure) {
    
    var cognitoUser = createCognitoUser(email);

    cognitoUser.confirmPassword(verificationCode, newPassword, {
        onSuccess: onSuccess,
        onFailure: onFailure
    });
}

export const authentication = {
    signin,
    register,
    forgotPassword,
    confirmPassword
};